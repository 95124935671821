<template>
    <div>
        <v-card color="white">
            <v-card-text style="font-size: 12px">
                <h2 class="mb-2">
                    Subject: {{ticketDetail.subject}}
                    <a 
                        :href="'https://api.whatsapp.com/send?phone='+ticketDetail.phone_number+'/&text=I salut you *'+ticketDetail.client_username+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on our services.'" 
                        target="_blank"
                        style="text-decoration: none"
                    >
                        <v-btn class="success" elevation="6" small>
                            <font-awesome-icon icon="fa-solid fa-share" class="mr-1"/>
                            Whatsapp Client 
                        </v-btn>
                    </a>
                </h2> 
                <v-row >
                    <v-col cols="5" >
                        <span><b>Category:</b> {{ticketDetail.category}}</span>
                    </v-col>
                    <v-col cols="7" >
                        <span ><b>Date:</b> {{ticketDetail.get_date_str }}</span>
                    </v-col>
                </v-row>
                <p class="pa-0 ma-0" style="font-size: 12px"><b>Message:</b> {{ticketDetail.message}}</p>
            </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-expansion-panels>
            <v-expansion-panel class="primary darken-2 white--text " style="color: white!important" >
                <v-expansion-panel-header class="text-center white--text  ">
                    Reply Messages
                </v-expansion-panel-header>
                <v-expansion-panel-content color="white pa-2 mt-2" style="" >
                    <v-row wrap>
                        <v-col cols="12" class="pa-2  mt-2">            
                            <v-row>
                                <v-col cols="1" class="text-xs-left">
                                    <font-awesome-icon icon="fas fa-lightbulb fa-2x"></font-awesome-icon>
                                </v-col>
                                <v-col cols="11" >
                                    <v-text-field 
                                        class="pa-0 mt-0"
                                        id="subject" 
                                        ref="subject" 
                                        v-model="subject"
                                        label="Subject"
                                        color="teal"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="1" class="text-xs-left mt-2">
                                    <font-awesome-icon icon="fas fa-comment fa-2x"> </font-awesome-icon>                            
                                </v-col>
                                <v-col cols="11" >
                                    <v-textarea class="pa-0 mt-0 ml-1"
                                        name="input-7-1"
                                        auto-grow  v-model="message"
                                        ref="message"
                                        color="teal"
                                        label="Message"
                                        :rules="[v => !!v || 'Message is required']"
                                        background-color=" lighten-4"
                                        rows="2" filled
                                        row-height="20" 
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="11" >
                                    <div class="mt-0" style="border-bottom: 1px solid grey; width: 100%; cursor: pointer"  @click="$refs.file.click()">
                                        <input @change="fileInput()" type="file" ref="file" name="file" id="file" class="inputfile" accept=".jpg,.png,.jpeg,.pdf" style=""/>
                                        <label for="file" class="teal"><strong><i class="fas fa-upload "> </i> Upload</strong></label>
                                        <span class="text-center" style="font-size: 12px; width: 100%">  {{inputFileName}}</span>
                                    </div>
                                </v-col>
                            </v-row>

                            <div class="mt-2 text-end">
                                <spinner v-if="loading" class="text-end"></spinner>
                                <v-btn 
                                    v-else
                                    small 
                                    class="elevation-6" 
                                    rounded 
                                    color="success" 
                                    @click="replyTicket()">
                                    <span> Submit</span>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-divider></v-divider>
        <div class="mt-3 pa-0" style="max-height: 600px; overflow: auto"> 
            <v-card v-for="item in ticketChats"
                :key="item.key" 
            >
                <v-card-text class="px-3 py-2 " v-if="item.support_team" style="border-left: 5px solid grey; font-size: 12px; cursor: pointer">
                    <v-row >
                        <v-col cols="12" sm="8" class="pt-1">
                            <h3 >Subject: {{item.subject}}</h3>
                            <small class="primary--text">By Support Team Member: {{ item.username }}</small>
                        </v-col>
                        <v-col cols="12" sm="4" class="pt-1">
                            <div >
                                <span><b>Date:</b> {{item.get_date_str}} </span>
                            </div>
                        </v-col>
                    </v-row>
                    <span><b>Message:</b> {{item.message}}</span>
                    <v-spacer></v-spacer>
                </v-card-text>
                <v-card-text class="px-3 py-2 " v-else style="border-left: 5px solid green; font-size: 12px; cursor: pointer">
                    <v-row >
                        <v-col cols="12" sm="8" class="pt-1">
                            <h3 >Subject: {{item.subject}}</h3>
                            <small class="success--text">By Client: {{ item.username }}</small>
                        </v-col>
                        <v-col cols="12" sm="4" class="pt-1">
                            <div >
                                <span><b>Date:</b> {{item.get_date_str}} </span>
                            </div>
                        </v-col>
                    </v-row>
                    <span><b>Message:</b> {{item.message}}</span>
                    <v-spacer></v-spacer>
                </v-card-text>
            <v-divider></v-divider>
            <v-divider></v-divider>
            </v-card>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import Spinner from "@/components/Spinner.vue";

    export default {
        components: {
            Spinner,
        },

        data() {
            return {
                loading: false,
                valid: true,
                subject: '',
                message: '',
                file: '',
                subject_help: false,
                message_help: false,
                file_help: false,
                inputFileName: "Select Accompanying File",
                ticketDetail: [],
                ticketChats: [],
            }
        },

        mounted(){
            // executes these after the page has been mounted
            document.title = "Manager: Njangi | Contact Us"
            this.getTicketDetail()
            this.getTicketChats()
        },

        methods: {

            fileInput(){
                var File= document.getElementById('file')
                if(File.files.item(0)){
                    var FileName = File.files.item(0).name
                    var FileSize = File.files.item(0).size
                    var FileType = File.files.item(0).type
                    console.log(FileType)
                    FileName = FileName.replace(/.*[\/\\]/, '_')  // https://stackoverflow.com/questions/3437786/get-the-size-of-the-screen-current-web-page-and-browser-window
                    var win = window,
                        doc = document,
                        docElem = doc.documentElement,
                        body = doc.getElementsByTagName('body')[0],
                        screenWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                        screenHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
                    
                    if (FileSize >= 5242880){
                        this.$store.commit('setSnackBarMessage', "Your accompanying file exceeded the size limit of 5Mb. Upload a file less than 5 megabytes")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Select Accompanying File"
                    }
                    else if(!FileType.includes("pdf") && !FileType.includes("jpeg") && !FileType.includes("jpg") && !FileType.includes("png") && !FileSize.includes("doc")){
                        this.$store.commit('setSnackBarMessage', "Your accompanying file must of format PDF, Image (.png, .jpeg, .jpg) or a file of format .doc")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Select Accompanying File"
                    }
                    else{
                        if (FileName.length > 20){
                            if (screenWidth <= 760){
                                FileName = FileName.substring(0, 10) + '...' + FileName.substring(FileName.length-9, FileName.length) 
                            }
                        }
                        this.inputFileName = FileName
                        this.file = this.$refs.file.files[0]
                    }
                }
                else{
                    this.inputFileName = "Select Accompanying File"
                }
            },
            
            async getTicketDetail(){
                const ticket_id = this.$route.params.id
                
                await axios
                    .get('/api/v1/manager/Njangi/get/contact_us/'+ticket_id+'/')
                    .then(response => {
                        this.ticketDetail = response.data  // get the data and fill into operators
                        console.log(this.ticketChats)
                    })
                    .catch(error => {
                        if (error.response){
                            for (const property in error.response.data){
                                this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                
            },
            
            async getTicketChats(){
                const ticket_id = this.$route.params.id  // get campaign category from url and send request to server
                
                await axios
                    .get('/api/v1/topup/get/contact_us/'+ticket_id+'/chats/')
                    .then(response => {
                        this.ticketChats = response.data  // get the data and fill into operators
                        console.log(this.ticketChats)
                    })
                    .catch(error => {
                        if (error.response){
                            for (const property in error.response.data){
                                this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },

            getHelpMessage(){
                if (this.subject_help){
                    this.subject_help = false
                    this.$store.commit('setDialogTitle', "Subject Name")
                    this.$store.commit('setDialogMessage', "Please input title to your reply. A short phrase describing the reply.")
                    this.$store.commit('activateDialog', true)
                }else if (this.message_help){
                    this.message_help = false
                    this.$store.commit('setDialogTitle', "Message")
                    this.$store.commit('setDialogMessage', "You can type in the message here.")
                    this.$store.commit('activateDialog', true)
                }else if (this.file_help){
                    this.file_help = false
                    this.$store.commit('setDialogTitle', "Accompanying Files")
                    this.$store.commit('setDialogMessage', "Any images/screenshots that may help in us better understand your message can be uploaded here.")
                    this.$store.commit('activateDialog', true)
                }
            },

            async replyTicket(){
                var File= document.getElementById('file')

                if(this.subject === ""){
                    this.$store.commit('setSnackBarMessage', "Please fill in the subject")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.subject.focus()  // this causes a an auto fucos to the element
                }
                else if(this.message == ""){
                    this.$store.commit('setSnackBarMessage', "Please fill in the message you want to send")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
                }
                else {                
                    this.loading = true

                    let formData = new FormData()
                    formData.append('file', this.file)
                    formData.append('subject', this.subject)
                    formData.append('message', this.message)
                    console.log(this.file)
                    const ticket_id = this.$route.params.id  // get campaign category from url and send request to server

                    await axios
                        .post('/api/v1/topup/send/contact_us/'+ticket_id+'/chats/reply/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            if(response.data[0].id === "0"){
                                window.location.reload()  // to reload and get another payment reference. this is for secuirity reasons
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
    
                    this.loading = false

                }

            },

        },
    }
</script>

<style scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }

</style>

